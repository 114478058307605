
<template>
<div class="card mb-3">
    <div class="card-body">
        <h4>Generalno</h4>
        <hr>

        <div class="settings-group">

            <div class="option-field input-group">
                <p class="setting-desc">Naziv firme</p>
                <div class="settings-round-value">
                    <input type="text" class="form-control" v-model="settings.company_name" name="company-name" id="company-name">
                </div>
            </div>

        </div>





    </div>
</div>
</template>

<script>

export default {
    
    name: "SettingsGeneral",

    components: {
    },

    data() {
        return {
            settings: {
                company_name: "MKEL",
            }

        }
    },

    computed: {

    },


    mounted() {
    },

    methods: {

    }

}
</script>

<style scoped>

    
</style>